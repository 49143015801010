<template>
    <!-- <div>
        <CreateNewEntity :type="type" :createNewEntityFN="createMenu" :successRoute="'single_menu'"></CreateNewEntity>
    </div> -->
    <Popup
		class="create-entity"
		:modal-id="modal_id"
		:on-close="onModalClose"
		custom-class="gray-header"
		:toggle-status="toggleStatus"
	>
        <template v-slot:header>
            {{ labels.create_new_menu }}
        </template>

		<template v-slot:body>
			<div class="create-entity__body">
                <FormulateInput
                    type="text"
                    name="title"
                    :placeholder="labels.title"
                    validation="required"
                    error-behavior="blur"
					v-model="userdata.title"
                />

                <FormulateInput
                    type="textarea"
                    name="description"
                    :placeholder="labels.description"
                    validation="required"
                    error-behavior="blur"
					v-model="userdata.description"
                />

            </div>
        </template>

		<template v-slot:footer>
			<div class="create-entity__footer">

				<FormulateInput
					type="submit"
					class="btn-purple"
					:label="labels.create"
					form="new-entity"
					@click="createMenuMt"
				/>

				<Button
                    :label="labels.close"
                    variant="btn-outline-purple"
                    font-weight="600"
                    :action="onModalClose"
                />
			</div>
		</template>
	</Popup>
</template>

<script>
import Popup from '@/components/Popups/Popup';
import Button from "@/components/Buttons/Button";
import { mapActions, mapGetters } from 'vuex';


export default {
    components: {
        Popup,
        Button,
    },
    props:{
        type:{
            type: String,
            default: 'menu'
        }
    },
    data() {
        return {
            modal_id: '1',
            toggleStatus: true,
            userdata: {
                title: '',
                description: '',
            }
        }
    },
    methods:{
        ...mapActions("notification", {
			addNotification: "addNotification"
		}),
        ...mapActions( 'menu', {
            createMenu: 'createMenu'
        } ),
        onModalClose(avoidBack) {
            if (!avoidBack) this.$router.go( -1 );
            let element = document.querySelector('.modal-backdrop');
            if ( element ) {
                element.remove();
                document.querySelector('body').classList.remove('modal-open');
            }
		},
        createMenuMt() {
            this.createMenu({ title: this.userdata.title, description: this.userdata.description })
                .then( res => {
                        if (res.success) {
                            this.toggleStatus = false;
                            this.onModalClose(true);
                            this.addNotification( { variant: 'success', msg: this.labels.menu_added });
                            const menuPageNum = this.$route.query.page || 1;
                            this.$router.push( { name: 'overview-menu', params: { page: menuPageNum } } );
                        } else if (res.error) {
                            this.addNotification( { variant: 'danger', msg: res.error })
                        }
                    })
				.catch( err => console.log( err ) );
        }
    },
    beforeMount() {
        // Redirect if user don't have permissions
        if (!this.isAllowed) {
            this.$router.push( {name: 'dashboard'} );
        }
    },
    computed: {
        ...mapGetters( 'helper', {
            hasPermissions: 'hasPermissions'
        }),
        ...mapGetters('helper', ['labels']),
        isAllowed() {
            return this.hasPermissions(['super', 'web']);
        }
    }
}
</script>

<style lang="scss" scoped>

.create-entity {

	&__header-title {
		font-family: "Oswald", sans-serif;
	}

	&__body {

		::v-deep .formulate-input {
			.formulate-input-wrapper {

				label {
					font-family: "Oswald", sans-serif;
					margin-bottom: 10px;
				}

				.formulate-input-element {
					max-width: 100%;
				}

                .formulate-input-group {
                    display: flex;
                    .formulate-input-group-item {
                        margin-right: 10px;
                        margin-bottom: 0;
                        
                    }
                    label {
                        margin-bottom: 0;
                        font-family: inherit;
                        font-weight: inherit;
                    }
                }
			}

			&.horizontal-label-input {
				.formulate-input-wrapper {
					display: flex;
					align-items: center;
					gap: 3rem;

					.formulate-input-element {
						flex: 1 1 auto;
					}
				}
			}
		}
	}

	&__footer {
		display: flex;
		align-items: center;

		::v-deep .formulate-input {
			margin-bottom: 0px !important;

			.formulate-input-element {
					button {
						margin-bottom: 0px;
						margin-right: 15px;
					}
				}
		}
	
	}
	
}

</style>
